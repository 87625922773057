.background {
	position: absolute;
	z-index: -1;
	inset: 0;
	mix-blend-mode: exclusion;
	opacity: 1;
	@extend %background-animation;
}

.starfield {
	position: relative;
	z-index: -1;
	animation: starfield-animation 10s ease forwards;
	opacity: 0;
}

%background-animation {
	animation: background-animation 33s ease infinite;
	background: linear-gradient(169deg, #000720, #09071b, #010e3a);
	background-size: 600% 600%;
}

@keyframes starfield-animation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.7;
	}
}

@keyframes background-animation {
	0% {
		background-position: 53% 0%;
	}

	50% {
		background-position: 48% 100%;
	}

	100% {
		background-position: 53% 0%;
	}
}
