.root {
	display: grid;
	min-height: 100vh;
	min-height: 100svh;
	place-content: center;
}

.content {
	z-index: 1;
	overflow: hidden;
	width: 90vw;
	max-width: 440px;
	border: 1px solid rgb(255 255 255 / 0.03);
	box-shadow: rgb(0 0 0 / 0.56) 0 22px 70px 4px;

	& > div {
		padding: 1rem;
	}
}
